.header {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.toggle > input {
    font-size: 0.6em;
    height: 2em;
    min-width: 4em;
}