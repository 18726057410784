.TouchScreen {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 90vh;
    min-width: 320px;
    padding: 1em;
    max-width: 600px;
}


/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .TouchScreen {
        font-size: 1.3em;
    }
}

/*Large devices (desktops, 900px and up)*/
@media (min-width: 900px) {
    .TouchScreen {
        font-size: 1.6em;
    }
}

