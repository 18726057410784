.quoteHeader {
    background-color: white;
    padding: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    width: 100vw;
}

.quoteHeader > p {
    max-width: 32em;
    text-align: center;
}

.quoteHeader span {
    font-weight: 700;
    font-size: var(--font-size-small);
    cursor: default;
}

.quoteHeader a {
    text-decoration: underline;
}
