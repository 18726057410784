
.Card {
    border-radius: 0.5em;
    padding: 1em 0;
    text-align: center;
    width: 20em;
}

.Default {
    background-color: var(--neutral-dark);
    color: var(--button-primary-font-color);
}