.label:hover {
    cursor: unset;
}

.planDownloadIcon {
    width: 3em;
}

.planDownloadIcon:hover {
    cursor: pointer;
}