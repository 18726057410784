@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800;900&display=swap');

@font-face {
    font-family: 'Naoko';
    src: url('./naoko-06-extrabold-webfont.woff') format('woff');
}

:root {

    --app-height: 100vh;
    --header-line-height: 1.35em;

    --paper-color: #F5F7Fa;
    --pencil-color: #dddddd;

    --peachy-pink: #FF5B65;
    --peachy-purple: #6D0E6F;
    --peachy-green: #33EFA9;

    --peachy-pink-light: #FFD7E5;
    --peachy-pink-light-pale: #FFEFF0;
    --peachy-pink-pale: #FF9A9F;
    --peachy-purple-light: #D3B7D4;
    --peachy-green-light: #C9F9E7;

    --neutral-color: #f5f7fa;
    --neutral-dark: #B0B8C7;

    --body-font: 'Poppins', sans-serif;
    --display-font: 'Naoko', 'Montserrat', sans-serif;

    --card-shadow: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.1));
    --button-focus: drop-shadow(0px 0px 5px var(--peachy-pink));
    --modal-shadow: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.1));

    --font-size-normal: 18px;
    --font-size-small: 0.9em;
    --font-size-xsmall: 0.75em;

    /* Root size not specified - Currently 16px */

    --font-size-root-xsmall: 0.875rem; /* 14px */
    --font-size-root-xxsmall: 0.75rem; /* 12px */
}

html {
    scroll-behavior: smooth;
}

html, body, .app-root {
    min-height: var(--app-height);
}

body {
    font-family: var(--body-font);
    font-size: var(--font-size-normal);
    background-color: var(--paper-color);
}

p {
    line-height: 1.5em;
}

h1 {
    font-weight: 900;
    font-size:3em;
    font-family: var(--display-font);
    text-transform: uppercase;
    line-height: var(--header-line-height);
}

h2 {
    font-weight: 900;
    font-size: 2em;
    font-family: var(--display-font);
    text-transform: uppercase;
    line-height: var(--header-line-height);
}

h3 {
    font-weight: 900;
    font-size: 1.5em;
    font-family: var(--display-font);
    text-transform: uppercase;
    line-height: var(--header-line-height);
}

h4 {
    font-weight: 700 ;
    font-size: 1.5em;
    font-family: var(--body-font);
    line-height: var(--header-line-height);
}

h5 {
    font-weight: 700 ;
    font-size: 1.2em;
    font-family: var(--body-font);
    line-height: var(--header-line-height);
}

h6 {
    font-weight: 400 ;
    font-size: 1em;
    font-family: var(--body-font);
    line-height: var(--header-line-height);
}

a, mark {
    color: var(--peachy-pink);
    background: none;
    cursor: pointer;
}

input[type=text], input[type=password], [type=number] {
    display: block;
    width: 100%;
    background-color: white;
    border: 1px solid var(--pencil-color);
    padding: 0.5em;
    border-radius: 0.5em;
}
