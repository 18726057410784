.infoPanel {
    display: flex;
    flex-direction: column;
    width: 17em;
    background-color: white;
    border-radius: 10px;
    row-gap: 0.5em;
    padding: 1em;
}

.infoPanel > h5 {
    font-size: var(--font-size-normal);
 }

.infoPanel p {
    font-size: 0.75rem;
}
