.modal {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--surface-color);
    border: 1px solid var(--neutral-dark);
    border-radius: 0.5em;
    filter: var(--modal-shadow);
    margin: auto;
    max-height: 70vh;
    position: relative;
    overflow-y: scroll;
    row-gap: 1em;
}