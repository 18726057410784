.textBox {
    border: 1px solid var(--neutral-dark);
    background-color: white;
    border-radius: 0.5em;
    padding: 0.5em 0.75em;
    line-height: 1em;
}

.label {
    width: 100%;
}
