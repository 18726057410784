.header > div {
    display: flex;
    column-gap: 2em;
}

.header > div:first-of-type {
    margin-right: auto;
    padding-left: 2em;
}

.header > div:last-of-type {
    margin-left: auto;
    padding-right: 2em;
}

.header a, .headerDisabled {
    font-size: 1rem;
    font-weight: normal;
    color: unset;
}
.headerDisabled {
    opacity: 0.3;
    cursor: default;
}