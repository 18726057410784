.errors {
    color: var(--peachy-purple);
    font-size: var(--font-size-small);
}

.errorItem::before {
    content: "!";
    border: 2px solid var(--peachy-purple);
    border-radius: 100%;
    width: 1.5em;
    height: 1.5em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-right: 1em;
}

.errorItem {
    display: none;
}

.errorItem:first-child {
    display: block;
}