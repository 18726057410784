.priceBreakdown {
    margin-top: 2em
}

.priceBreakdown tr > * {
    padding: 0.5em 1em
}

.priceBreakdown td:last-child {
    width: 40%
}

.priceBreakdown td:nth-child(2) {
    text-align: right;
}

.priceBreakdown th:nth-child(2) {
    text-align: right;
    white-space: nowrap;
}
