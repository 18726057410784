.whatsNotCovered {
    background-color: white;
    display: flex;
    gap:3em;
    flex-direction: column;
    align-items: center;
    padding: 4em 8em;
    margin-top: 2em;
    width: 100vw;
}

.whatsNotCovered ul {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    max-width: 1600px;
    width: 100%;
}
