.PasswordBox {
    position: relative;
    display: flex;
}

.PasswordBox i {
    position: absolute;
    transform: translateY(-50%);
    display: flex;
    right:0.5em;
    color: var(--peachy-pink);
    top:50%;
}
