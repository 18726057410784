.Footer {
    position: sticky;
    bottom: 0;
    color: white;
    font-family: var(--display-font);

    width: 100vw;
    max-height: 90px;
    height: 90px;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.Footer label {
    font-size: 2rem;
    text-transform: uppercase;
    margin-right: 10px;
}

.Footer > div {
    padding: 0 4em 0 2em;
}

.Footer .CurrentPrice {
    background-color: var(--primary-color-1);
    display: flex;
    align-items: center;
}

.Footer .NewPrice {
    background-color: var(--peachy-purple);
    align-items: center;

    display: flex;
    justify-content: space-between;
    padding-right: 5em;
}

.Footer .NewPrice button {
    padding-left: 2em;
    padding-right: 2em;
}

.ProceedButton {
    background-color: #99579a;
}
