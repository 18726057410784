.downloadColumn {
    width: 60%;
    text-align: end;
}

.redHighlight {
    color: var(--primary-color-1);
    font-weight: bold;
}

