.table {
    position: relative;
    text-align: left;
    width: 100%;
}

.table th {
    text-align: center;
    cursor: default;
    white-space: nowrap;
}

.table th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    z-index: 11;
}

.table :where(th, td) {
    padding: 0.5rem;
    position: relative;
    vertical-align: middle;
    font-size: medium;
}

.table :where(thead, tfoot) :where(th, td) {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
}

.index {
    width: 40px;
}

.name {
    min-width: 10em;
}

.dob {
    min-width: 10em;
    max-width: 10em;
}

.dob input::placeholder {
    font-size: 0.9em;
}

.address {
    width: 20em;
}

.email {
    width: 17em;
}

.plan {
    width: 9em;
}

.dependants {
    display: inline-grid;
    grid-auto-flow: column;
    width: 80px;
}

.dependants span {
    width: 2em;
    /* TODO get SVG where icons are more centered */
    margin-top: 3px;
}

.dependants span:hover {
    cursor: default;
}

.dependants span.addBtn:hover {
    cursor: pointer;
}

.plan i {
    position: relative;
    right: 15px;
}

.delete {
    min-width: 20px;
}
