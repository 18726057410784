.progressMessage {
    width: 100%;
    text-align: center;
}

.progressMessageError {
    color: red;
}

.buttonsContainer button {
    font-weight: normal;
    margin: 0.5em;
}
