.AddressLookup > label {
    width: 100%;
}


.inputOverflow input[type=text] {
    box-sizing: border-box; /* Stop ellipsis from overlapping with icon*/
    padding-right: 2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.invalidField input[type=text],
label.invalidField {
    background-color: var(--peachy-pink-light-pale)
}
