.IamModal {
    min-width: 25em;
    flex-direction: column;
    padding: 1.5em 3em;
}

.IamModal a {
    text-align: right;
    font-size: 0.8em;
}

.IamModal ul {
    color: var(--peachy-purple);
    margin-left: 1em;
    list-style-type: disc;
    font-size: 0.7em;
}

.IamModal mark {
    background-color: white;
    color: var(--peachy-pink);
    font-size: 0.8em;
    font-weight: 600;
}

.IamModal mark::before {
    content: '!';
    width: 1em;
    display: inline-block;
    background-color: var(--peachy-pink);
    color: white;
    text-align: center;
    margin-right: 0.5em;
    border-radius: 100%;
}
