.planEmployees {
    display: grid;
    grid-template-columns: var(--col-width-benefit-options) repeat(auto-fit, minmax(var(--col-width-plan), auto));
    column-gap: 2em;
}

.planEmployees .panel {
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
}

.planEmployees .bench {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: var(--col-width-benefit-options);
    height: max-content;
    gap: 5px;
}

.employee, .addEmployee {
    width: var(--col-width-plan);
    background-color: var(--surface-color);
    border-radius: 10px;
    padding: 6px;
    text-align: center;
    min-height: 3em;
}

.employee {
    border: 1px solid var(--primary-color-1);
}

.portal .employee.changed {
    border-color: var(--peachy-purple);
    background-color: #e7e0ec;
    border-width: 2px;
}

.employee.readonly:not(.changed) {
    border: unset;
}

.employee.readonly:hover {
    cursor: default;
}

.employee.deleted {
    text-decoration: line-through;
}

.addEmployee {
    border: unset;
}

.addEmployee:hover {
    cursor: pointer;
}

.addEmployee > span {
    font-size: 0.8em;
}

.dragging {
    opacity: 0.25;
}

.overlay .readonly:hover {
    cursor: not-allowed;
}

.employee:hover {
    cursor: grab;
}

.employee .firstName {
    font-size: var(--font-size-xsmall);
    position: relative;
    top: 0;
}

.employee .dependantCount {
    font-size: var(--font-size-root-xxsmall);
}

.employee .lastName {
    font-weight: 700;
}

.employee .firstName,
.employee .lastName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidePanel {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.sidePanel .benchContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.sidePanel .benchContainer header {
    display: inline-flex;
}

.sidePanel > .benchContainer i {
    margin-left: auto;
    cursor: pointer;
}

.cancel,
.edit {
    height: 3em;
    padding: 6px;
}

.benchArea {
    min-height: 190px;
    border-radius: 10px;
    border: 4px dashed var(--pencil-color);
    display: flex;
    padding: 5px 3px;
}