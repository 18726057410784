.quoteFooter {
    position: sticky;
    bottom: 0;
    background-color: var(--primary-color-1);
    color: white;
    padding: 0 8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 90px;
    max-height: 90px;
}

@media screen and (max-width: 1400px) {
    .quoteFooter {
        font-size: var(--font-size-small);
    }
}

@media screen and (max-width: 800px) {
    .quoteFooter {
        padding-left: 2em;
        padding-right: 6em;
    }

    .quoteFooter button {
        padding-left: 2em;
        padding-right: 2em;
    }
}