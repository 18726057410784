.listContainer {
    margin: 0 150px;
    align-self: normal;
}

.listContainer > section {
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    margin-top: 4em;
}

.table {
    border-collapse: separate;
    border-spacing: 0 10px;
}

/* Space between thead and tbody. Border spacing + line height*/
.table tbody:before {
    content:"@";
    line-height: 20px;
    visibility: hidden;
}

.table :where(th) {
    color: var(--neutral-dark);
}

.table :where(th, td) {
    background-color: white;
    font-size: medium;
    font-weight: normal;
    padding: 2em;
    font-size: var(--font-size-root-xxsmall);
}

.table :where(th,td):first-child {
    border-radius: 10px 0 0 10px;
}

.table :where(th,td):last-child {
    border-radius: 0 10px 10px 0;
}

.dateIssued {
    width: 60%;
}

.link {
    color: initial;
}
