.Summary {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.Summary section {
    padding: 2em 0 2em 0;
}

.Summary section:last-child {
    padding-bottom: 5em;
}

.WhiteSection {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.Heading {
    padding-top: 3em;
}

.DetailsContainer {
    width: 470px;
}

/* === FONT SIZES === */

.WhiteSection p,
#payment-form span:nth-of-type(1),
.StartDate {
    font-size: var(--font-size-root-xsmall);
}

.PlanConfig {
    font-size: var(--font-size-small);
}
