.planMembers {
    width: 100%;
}

.member {
    width: var(--col-width-plan);
    background-color: var(--surface-color);
    border-radius: 10px;
    text-align: center;
    height: 3em;
    line-height: 3em;
    font-weight: bold;
    pointer-events: none;
}