.Contents {
    display: flex;
    gap: 1em;
    flex-direction: column;
}

.Details {
    display: inline-grid;
    grid-template-columns: 10em 1fr;
}

.Details p {
    line-height: unset;
}