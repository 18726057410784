.PremiumDetails {
    margin-top: 1em;
    row-gap: 2em;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.Cost {
    border-radius: 0.5em;
    padding: 1em 0;
    text-align: center;
    width: 20em;
}

.Month {
    background-color: var(--button-primary-bg-color);
    color: var(--button-primary-font-color);
}

.Year {
    background-color: var(--button-dark-bg-color);
    color: var(--button-dark-font-color);
}