.infoPanels {
    display: grid;
}

.infoPanels > div {
    min-height: 150px;
}

.infoPanels > div:nth-child(2) {
    justify-self: center;
}

.infoPanels > div:last-child {
    justify-self: end;
}

.infoPanels span {
    color: var(--primary-color-1);
    font-weight: bold;
}

.yourPlanInfoPanels {
    grid-template-columns: 1fr 1fr 1fr;
}

.billingInfoPanels {
    grid-template-columns: 1fr 1fr;
    width: 38em;
}