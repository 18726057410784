
.SubHeader {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.SubHeader * {
    font-size: var(--font-size-root-xsmall);
}

.Edit {
    text-align: right;
}

.Edit > button {
    padding: unset;
    border: unset;
    background-color: unset;
    font-weight: normal;
}

.Edit i {
    margin-left: 5px;
}
