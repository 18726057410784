
.captureForm {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 1.5em;
    width: 29em;
    padding: 2em;
}

.captureForm fieldset {
    align-self: stretch;
    display: flex;
    column-gap: 1em;
}

.captureForm button {
    margin-top: 1em;
    align-self: center;
}

.captureForm input:focus {
    border-color: var(--form-highlight);
}

.captureForm nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 0.9em;
}
