.Summary {
    display: flex;
    flex-direction: column;
    margin-top: 3em;
    margin-bottom: 5em;
    width: 500px;
    gap: 3em;
}

.Content {
    display: flex;
    flex-direction: column;
    gap: 2em;
    font-size: var(--font-size-root-xsmall);
}

.SummaryChanges {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
}

.SummaryChanges > ul {
    margin-inline-start: 0.5em;
    list-style: disc inside;
}

.Content h5 {
    font-size: var(--font-size-root-xsmall);
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    gap: 1em;
}

.ButtonContainer button {
    /* TODO: make a 'small' button OR have the button always fill the bounding container */
    padding-left: 2em;
    padding-right: 2em;
}

.CurrentPremium {
    font-size: var(--font-size-root-xsmall);
}

.Premiums {
    row-gap: 2em;
    align-items: center;
    display: flex;
    flex-direction: column;

    & div {
        font-size: 1.5em;
        border-radius: 20px;
    }
}

.NewPremium {
    background-color: var(--button-primary-bg-color);
    color: var(--button-primary-font-color);
}

.NewAnnualPremium {
    background-color: var(--button-dark-bg-color);
    color: var(--button-primary-font-color);
}

.PremiumText {
    display: flex;
    flex-direction: column;
    gap: 1em
}