.Card {
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: white;
    border-radius: 1em;
    padding: 1em;
    filter: var(--card-shadow)
}


.highlight {
    background-color: var(--peachy-purple-light)
}
