.moreInfoModal {
    padding: 2em 6em;
    max-width: 50em;
    min-width: 35em;
    font-size: var(--font-size-small);
}

.moreInfoModal > section {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    gap: 0.6em;
    margin-top: 1em;
}

.moreInfoModal ul {
    display: flex;
    flex-direction: column;
    list-style: disc inside;
    gap: 0.3em;
    padding-top: 0.3em;
    padding-left: 1.5em;
    text-indent: -1.2rem;
}