
.SubHeader {
    display: grid;
    grid-template-columns: auto 16px;
}

.SubHeader * {
    font-size: inherit;
}

.Edit {
    text-align: right;
}

.Edit > button {
    padding: unset;
    border: unset;
    background-color: unset;
    color: initial;
    font-weight: normal;
    font-size: var(--font-size-xsmall);
}