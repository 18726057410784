.priceArticle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.priceExplained {
    font-size: var(--font-size-xsmall);
}

.priceBreakdownModal {
    max-width: 51em;
    padding: 2em 7em 2em 7em;
}

@media screen and (max-width: 600px) {
    .priceBreakdownModal {
        padding: 5em 1em
    }
}