.planSelector {
    display: flex;
    align-items: center;
    border: 1px solid var(--pencil-color);
    padding: 0.5em 1em 0.5em 0.5em;
    border-radius: 0.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    box-sizing: border-box; /* Stop ellipsis from overlapping with icon*/
}

.planSelector > i {
    position: absolute;
    right: 15px;
    pointer-events: none;
}