/* TODO re-use this for other pages */
.listContainer {
    margin: 0 200px;
    align-self: normal;
}

.members {
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    margin-top: 4em;
}

.members .header {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}

.members .searchBar {
    grid-column-start: 3;
    display: flex;
}

.members .searchBar input[type=text] {
    max-width: fit-content;
    margin-left: auto;
    position: relative;
    padding-left: 2em;
}

.members .searchBar i {
    padding: 0.5em;
    position: relative;
    z-index: 1;
    left: 2em;
    top: 3px;
    pointer-events: none;
}

.edit {
    color: initial;
}

.membersTable {
    border-collapse: collapse;
    border-spacing: unset;
}

.membersTable :where(th, td) {
    padding: 2em 2em 2em 0;
}

.membersTable :where(th, td):first-child,
.membersTable :where(th, td):last-child {
    width: 5px;
}

.membersTable .rowGap {
    opacity: 1;
    visibility: hidden;
    padding: 0.5em;
}

.middleRows>td:first-child,
.middleRows>td:last-child {
    border-radius: 0;
}

.middleRows>td:not(:first-child):not(:last-child),
.lastRow>td:not(:first-child):not(:last-child) {
    border-top: 2px solid var(--neutral-dark);
}

/* Table row block borders */
.firstRow>td:first-child {
    border-radius: 10px 0 0 0;
}

.firstRow>td:last-child {
    border-radius: 0 10px 0 0;
}

.lastRow>td:first-child {
    border-radius: 0 0 0 10px;
}

.lastRow>td:last-child {
    border-radius: 0 0 10px 0;
}

.manageButton {
    max-width: 20em;
}