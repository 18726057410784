.button {
    text-align: center;
    border-radius: 0.5em;
    font-weight: bold;
    transition: all 200ms;
    font-size: var(--font-size-normal);
}

.button:disabled {
    background-color: var(--neutral-dark);
    opacity: 0.5;
}

/* variants */
.solid {
    padding: 0.75em 4em;
}

.solid:focus {
    filter: var(--button-focus);
}

.solid:not(:disabled):hover {
    filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.1)) drop-shadow(2px 4px 3px rgba(0, 0, 0, 0.1));
    transform: scale(1.05);
}

.link {
    border: none !important;
}

/* themes */
.primary {
    background-color: var(--button-primary-bg-color);
    color: var(--button-primary-font-color);
}

.secondary {
    background-color: var(--button-secondary-bg-color);
    color: var(--button-secondary-font-color);
    border: 2px solid var(--button-secondary-font-color);
}

/*TODO Remove when doing payment type for subscription*/
/*Can we get a outline style for secondary theme? */
.secondary:disabled {
    background-color: var(--button-secondary-bg-color);
    color: var(--neutral-dark);
    border-color: var(--neutral-dark);
}

.light {
    background-color: var(--button-light-bg-color);
    color: var(--button-light-font-color);
}

.dark {
    background-color: var(--button-dark-bg-color);
    color: var(--button-dark-font-color);
}

.darkSecondary {
    background-color: var(--button-secondary-bg-color);
    color: var(--button-dark-bg-color);
    border: 2px solid var(--button-dark-bg-color);
}



