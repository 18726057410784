:root {
    --broker-company-typeahead-width: 580px
}

.content {
    display: grid;
    grid-template-columns: var(--broker-company-typeahead-width) 430px;
    justify-content: space-between;
}

input[type=text].invalid {
    background-color: var(--peachy-pink-light-pale);
}

.companyTypeahead {
    width: var(--broker-company-typeahead-width);
}