.startDate {
    display: flex;
    flex-direction: column;
    padding: 2em 0;
    gap: 1em
}

.startDate > div {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1em;
}

.startDate > div label {
    max-width: 9rem;
}

.startDate i {
    font-size: 1.5em;
    color: var(--peachy-pink);
    cursor: pointer;
}

.startDate i:focus {
    filter: var(--button-focus);
}

.invalidField input[type=text] {
    border-color: var(--form-error);
}
