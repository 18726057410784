.container {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.container > p:first-child.input{
    margin-top: 2.4em;
}

.input {
    border: 2px solid var(--pencil-color);
    padding: 0.5em;
    border-radius: 0.5em;
    cursor: pointer;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.input,
.list {
    min-width: 260px;
    font-size: 1rem;
}

.listItem {
    padding: 0.5em;
}