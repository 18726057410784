.Help {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    padding: 3rem;
    cursor: default;
}

.Help > p {
    text-align: center;
    max-width: 23rem;
}

.Help > div {
    display: flex;
    column-gap: 1rem;
}

.Help > div button {
    border-width: 2px;
}