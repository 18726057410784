.modal {
    max-width: 80%;
    min-width: 70em;
}

.modal section {
    padding: 2em 2em 1em 2em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    overflow-y: scroll;
    align-items: center;
}

.modal section, .modal .footerContainer {
    width: 100%;
}

.modal p {
    font-size: var(--font-size-small);
    padding: 0 15%;
    text-align: justify;
    text-align-last: center;
}

.modal h3, p {
    cursor: default;
}

.table {
    width: inherit;
}

.footerContainer {
    padding-bottom: 1em;
    display: grid;
    grid-template-columns: 1fr 2fr;
}

.footerContainer button {
    padding-left: 2em;
    padding-right: 2em;
}

.footerContainer .add {
    grid-column-start: 1;
    width: max-content;
}

.footerContainer .add button {
    font-weight: normal;
    margin-right: 1em;
}

.footerContainer .done {
    text-align: right;
}
