.Exclusion {
    background-color: var(--neutral-color);
    border-radius: 0.5em;
}

.Exclusion > div {
    padding: 1em 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.Exclusion article {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    cursor: default;
    padding: 0 2em;
    font-size: var(--font-size-small);
}


.Exclusion article > *:last-child {
    padding-bottom: 2em;
}

.Exclusion span {
    font-size: 2em;
}

.Exclusion h6 {
    font-weight: 700;
}

.Exclusion article > ul {
    list-style: disc;
    padding-left: 2em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3em;
    grid-row-gap: 5px;
}

.Exclusion article > p {
    display: inline-block;
}

@media screen and (max-width: 600px) {
    .Exclusion article > ul {
        column-count: 1;
    }
}