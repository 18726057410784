.header {
    height: var(--header-height);;
    align-items: center;
    justify-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: var(--header-color);
}

.header button {
    font-size: 2em;
}
