.annualLimitInfo {
    cursor: default;
}

.annualLimitInfo > mark {
    font-weight: 700;
}

.annualLimitInfoModal {
    background-color: var(--surface-color);
    border: 1px solid var(--neutral-dark);
    border-radius: 0.5em;
    filter: var(--modal-shadow);
    position: relative;
}

.annualLimitInfoModal > section {
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    row-gap: 2em;
    margin-top: 1em;
    margin-bottom: 2em;
    padding: 2em 6em;
    max-width: 50em;
    min-width: 35em;
    max-height: 70vh;
    overflow: scroll;
}

.annualLimitInfoModal > section dl {
    display: grid;
    column-gap: 1em;
    grid-template: auto / 1fr 1fr;
    width: fit-content;
}

.annualLimitInfoModal > section h6 {
    font-weight: bold;
}

.annualLimitInfoModal > section :is(dl,p) {
    line-height: 1.5em;
}

.annualLimitInfoModal > section cite {
    font-size: var(--font-size-small);
}