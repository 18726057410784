:root {
    --plan-border-active-color: lime;
    --plan-border: 2px solid var(--surface-color);
    --plan-row-height: 80px;
}

.icon {
    display: contents;
}

.crossIcon {
    transform: scale(0.5);
}

.cardContainer {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    column-gap: 2em;
    margin-bottom: 2em;
}

.cardContainer .card .title, .cardContainer .card div:last-child {
    background: none;
}

.cardContainer .title, .cardContainer div {
    background-color: var(--surface-color);
}

.cardContainer .optionalBenefitLimit {
    font-size: var(--font-size-root-xxsmall);
}

.card {
    text-align: center;
    border-radius: 10px;
}

.card > * {
    height: var(--plan-row-height);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card:has(.transparent) {
    opacity: 0;
}

.labelCard {
    width: var(--col-width-benefit-options);
}

.labelCard .name {
    text-align: left;
}

.labelCard .label {
    display: grid;
    grid-template-columns: 5em 1fr 20px;
    align-items: center;
    column-gap: 0.5em;
    text-transform: uppercase;
    padding: 0 15px 0 0;
}

.planCard {
    border: var(--plan-border);
}

.planCard > * {
    width: var(--col-width-plan);
}

.planCard.activeEdit {
    border-color: var(--plan-border-active-color);
}

.planCard.activeView {
    border-color: var(--neutral-dark);
}

.title {
    font-weight: 700;
    padding-top: 20px;
    display: inline-block;
    height: unset;
}

.label:hover {
    cursor: pointer;
}

.label .name {
    font-weight: 700;
}

.toggle > input {
    font-size: 0.6em;
    height: 2em;
    min-width: 4em;
}
