.myQuotesHeader {
    display: flex;
    justify-content: space-between;
}

.goToQuote {
    color: black;
    text-decoration: underline;
}

.addQuote {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.addQuote > button {
    width: fit-content;
    padding: 1rem 2rem;
    font-size: var(--font-size-normal);
}