
.WelcomeContainer {
    background-color: var(--primary-color-1);
    position: absolute;
    top: var(--header-height);
    overflow: hidden;
    bottom: 0;
    left: 0;
    right: 0;
}

.Welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: center;
    row-gap: 1em;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Welcome p {
    line-height: unset;
}

.textContent {
    width: 30em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    padding-bottom: 1em;
}

.email,
.email > button {
    font-size: var(--font-size-root-xsmall);
    vertical-align: unset;
}

.navigation {
    display: flex;
    flex-direction: row;
    column-gap: 1em;
}

.navigation button {
    padding: 0.75em;
}