.CompanyTypeahead {
    display: flex;
    flex-direction: column;
    min-width: -webkit-fill-available;
}

.SuggestionsList {
    border: 2px solid var(--peachy-pink);
    background-color: var(--form-input-bg-color);
    border-radius: 0.5em;
    transform: translateY(0.2em);
    min-height: 4em;
    max-height: 14em;
}

.SuggestionsItem {
    display: block;
    padding: 0.2em 1em;
}

.SuggestionsItem:hover {
    cursor: pointer;
    background-color: var(--form-hover);
}

.selectedSuggestions, .selectedSuggestions:hover {
    background-color: var(--form-selected);
    color: var(--form-input-bg-color);
}
