.text:hover {
    cursor: pointer;
}

.list {
    border: 2px solid var(--peachy-pink);
    border-radius: 0.5em;
    font-size: var(--font-size-small);
    overflow-y: scroll;
}

.listItem {
    padding: 0.2em 0.5em;
}

.listItem:hover {
    background-color: var(--form-hover);
    cursor: pointer;
}

.selectedItem {
    color: white;
}

.selectedItem, .selectedItem:hover {
    background-color: var(--peachy-pink);
}
