.addressList {
    border: 2px solid var(--peachy-pink);
    background-color: var(--form-input-bg-color);
    border-radius: 0.5em;
    outline: none;
    transform: translateY(2.1em);
    height: 7em;
    min-width: 25em;
}

.addressItem {
    display: block;
    padding: 0.2em 1em;
}

.addressItem:hover {
    cursor: pointer;
    background-color: var(--form-hover);
}

.selectedAddress, .selectedAddress:hover {
    background-color: var(--form-selected);
    color: var(--form-input-bg-color);
}

.invalidField input[type=text] {
    border-color: var(--form-error);
}
