.form {
    display: flex;
    flex-direction: column;
    row-gap: 1.5em;
}

.form fieldset {
    line-height: 1em;
    align-self: stretch;
    display: flex;
    column-gap: 1em;
}

.form fieldset div {
    border: 1px solid var(--neutral-dark);
    background-color: white;
    border-radius: 0.5em;
    padding: 0.5em 0.75em;
    width: 100%;
    line-height: 1em;
}

.form button {
    align-self: center;
}
