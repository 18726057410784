.modal {
    row-gap: 1em;
    padding: 2em 7em;
    border: 1px solid var(--neutral-dark);
    border-radius: 1em;
    cursor: default;
}

.header {
    text-align: center;
    row-gap: 2em;
}

.nudgeSelectors {
    line-height: 2em;
}

.nudgeSelectorText {
    font-weight: bold;
}

.nudgeSelectorList{
    border-radius: 0.5em;
}

.nudgeSelectorListItem {
    padding: 0.2em 0.5em
}

.nudgeSelectorListItem:hover {
    background-color: var(--form-hover);
}

.nudgeSelectorListItemSelected {
    background-color: var(--peachy-pink);
    color: white;
}
.nudgeSelectorListItemSelected:hover {
    background-color: var(--peachy-pink);
}

.nudgeLeft {
    color: unset;
    padding: 0;
    width: 2em;
    background-color: transparent;
}

.nudgeRight {
    color: unset;
    padding: 0;
    width: 2em;
    background-color: transparent;
}

.monthTable {
    font-size: 0.8em;
}

.monthTable th {
    font-weight: 500;
}

.monthTable td {
    border: none;
}

.weekNames {
    text-transform: uppercase;
}

.allDays {
    border-radius: 5em;
    height: 3em;
    width: 3em;
    margin: 0.5em;
}

.inMonth {
    background-color: var(--neutral-color);
}
.outMonth {
    opacity: 50%;
}

.selected {
    background-color: var(--peachy-pink);
    color: white;
    font-weight: normal;
}
