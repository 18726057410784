.container {
    display: flex;
    flex-direction: column;
    row-gap: 3em;
    margin-top: 2em;
}

.rowStructure {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1em;
}

.rowStructure > div:last-of-type > h4 {
    text-align: end;
}

.details {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.subTitle {
    font-size: var(--font-size-root-xsmall);
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
}
